
import './App.css';
import Dashboard from './Components/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import CreateField from './Pages/Fields/Create';
import Fields from './Pages/Fields/Index';
import FieldDetail from './Pages/Fields/Detail';
import EditField from './Pages/Fields/Edit';
import FieldDelete from './Pages/Fields/Delete';
import CreateProduct from './Pages/Products/Create';
import EditProduct from './Pages/Products/Edit';
import ProductDelete from './Pages/Products/Delete';
import Products from './Pages/Products/Index';
import ProductDetail from './Pages/Products/Detail';

import CreateMarket from './Pages/Markets/Create';
import EditMarket from './Pages/Markets/Edit';
import MarketDelete from './Pages/Markets/Delete';
import Markets from './Pages/Markets/Index';
import MarketDetail from './Pages/Markets/Detail';
import CreateOffer from './Pages/Offers/Create';
import EditOffer from './Pages/Offers/Edit';
import OfferDelete from './Pages/Offers/Delete';
import Offers from './Pages/Offers/Index';
import OfferDetail from './Pages/Offers/Detail';
import CreateCity from './Pages/Cities/Create';
import CityDetail from './Pages/Cities/Detail';
import Cities from './Pages/Cities/Index';
import DeleteCity from './Pages/Cities/Delete';
import EditCity from './Pages/Cities/Edit';
import Users from './Pages/Users/Index';
import Notifs from './Pages/Notifs/Index';
import CreateNotif from './Pages/Notifs/Create';
import GroupedReport from './Pages/Reports/GroupedReport';
import Createcoupon from './Pages/Coupons/Create';
import Editcoupon from './Pages/Coupons/Edit';
import Deletecoupon from './Pages/Coupons/Delete';
import Coupons from './Pages/Coupons/Index';
import CouponDetail from './Pages/Coupons/Detail';
import Packages from './Pages/packages/Index';
import Editpackage from './Pages/packages/Edit';
import Subscriptions from './Pages/Subscriptions/Index';
import Providers from './Pages/Providers/Index';
import Categories from './Pages/Categories/Index';
import Services from './Pages/Services/Index';
import Trips from './Pages/Trips/Index';
import Menu from './Pages/Menu/Index';
import MenuCategories from './Pages/MenuCategories/Index';

function App() {
  return (
    <div className="App">
      <Router >
        <Routes >
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="/admin/providers" element={<Providers />} />
            <Route path="users" element={<Users />} />
            <Route path="services" element={<Services />} />
            <Route path="trips" element={<Trips />} />
            <Route path="menu" element={<Menu />} />
            <Route path="menu-category" element={<MenuCategories />} />


            {/* Fields */}
            <Route path="field/add" element={<CreateField />} />
            <Route path="field/edit" element={<EditField />} />
            <Route path="field/delete" element={<FieldDelete />} />
            <Route path="fields" element={<Categories />} />
            <Route path="fields/detail" element={<FieldDetail />} />

            {/* Markets */}
            <Route path="market/add" element={<CreateMarket />} />
            <Route path="market/edit" element={<EditMarket />} />
            <Route path="market/delete" element={<MarketDelete />} />
            <Route path="markets" element={<Markets />} />
            <Route path="market/detail" element={<MarketDetail />} />

            {/* Products */}
            <Route path="Product/add" element={<CreateProduct />} />
            <Route path="Product/edit" element={<EditProduct />} />
            <Route path="Product/delete" element={<ProductDelete />} />
            <Route path="Products" element={<Products />} />
            <Route path="Product/detail" element={<ProductDetail />} />

            {/* coupons */}
            <Route path="coupon/add" element={<Createcoupon />} />
            <Route path="coupon/edit" element={<Editcoupon />} />
            <Route path="coupon/delete" element={<Deletecoupon />} />
            <Route path="coupons" element={<Coupons />} />
            <Route path="coupon/detail" element={<CouponDetail />} />

            {/* packages */}
            <Route path="packages" element={<Packages />} />
            <Route path="package/edit" element={<Editpackage />} />

            {/* Offers */}
            <Route path="Offer/add" element={<CreateOffer />} />
            <Route path="Offer/edit" element={<EditOffer />} />
            <Route path="Offer/delete" element={<OfferDelete />} />
            <Route path="Offers" element={<Offers />} />
            <Route path="Offer/detail" element={<OfferDetail />} />

            {/* Cities */}
            <Route path="City/add" element={<CreateCity />} />
            <Route path="City/edit" element={<EditCity />} />
            <Route path="City/delete" element={<DeleteCity />} />
            <Route path="Cities" element={<Cities />} />
            <Route path="City/detail" element={<CityDetail />} />

            {/* Notifs */}
            <Route path="notifs" element={<Notifs />} />
            <Route path="notif/add" element={<CreateNotif />} />

            {/* Notifs */}
            <Route path="/admin/grouped-report" element={<GroupedReport />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;


