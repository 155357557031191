

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";


export default function Editcoupon() {

  var [image,setImage] = useState()
  const location = useLocation();
  const row = location.state.row;
  const handleNewImage = event => {
    image = event.target.files
  }

  const uploadImages=async (image)=>{
    const arr = []
    for (const element of image) {
       const url = await uploadImage(element)
       arr.push(url)
     }
     console.log(arr.join(","));
     return arr.join(",")
  }
  const [cities, setCities] = useState()
  const [packages, setpackages] = useState()
  const getpackages = async () => {
    try {
      const response = await fetch(baseurl + "package/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log(json);
      setpackages(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  const getCities = async () => {
    try {
      const response = await fetch(baseurl + "city/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log("cities");
      console.log(json);
      setCities(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  const [loader, showLoader, hideLoader] = useLoader(); 

  const navigateTo = useNavigate()
  const postForm = async (event) => {
    showLoader()
    event.preventDefault();
    const formdata = new FormData(event.target);
    formdata.append("id", row.id)

    const response = await fetch(baseurl + "coupon/update", {
      method: "POST",
      headers: {

        "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: formdata
    });
    const json = await response.json();
    hideLoader()
    successNotify()

    console.log(json);
    navigateTo("/admin/coupons");

  };

  const initData = async () => {
        await getCities()
        await getpackages()
    
}

  useEffect(() => {
       
        initData()
    
}, []);

  return <div>
    {loader}
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الكوبونات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">تعديل متجر</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات الكوبون</h6>
            <hr />
            <form onSubmit={postForm} encType="multipart/form-data" >
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
             
              

         

                <div className="col-6">
                  <label className="form-label">وصف مختصر عن الكوبون  </label>
                  <input onInvalid={onInputInvalid}
                   defaultValue={row.description}
                    onInput={OnInput} required autoComplete="off" name="description" type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">القيمة  </label>
                  <input onInvalid={onInputInvalid}
                  defaultValue={row.amount}
                    onInput={OnInput} required autoComplete="off" name="amount" type="number" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">الحد الاقصي للاستخدام  </label>
                  <input onInvalid={onInputInvalid}
                  defaultValue={row.quantity}
                    onInput={OnInput} required autoComplete="off" name="quantity" type="number" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">تاريخ البداية  </label>
                  <input  onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="startAt" type="date" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">تاريخ الانتهاء  </label>
                  <input  onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="expiredAt" type="date" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label"> الحالة </label>
                  { (
                    <select

                      onInvalid={onInputInvalid}
                      onInput={OnInput} required
                      name="status"
                      id="school-id"
                      className="form-select"
                    >

                      <option value={""}>
                        اختر
                      </option>
                      <option value={1}>
                        مفعل
                      </option>
                      <option value={0}>
                        غير مفعل
                      </option>
                      
                    </select>
                  )}

                  <span asp-validation-for="ProductId" className="text-danger" />
                </div>
               
                <div className="col-12">
                  <div className="d-grid">
                    <input onInvalid={onInputInvalid}
                      onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

}