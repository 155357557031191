import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, successNotify, uploadImage } from "../../constants";

export default function EditField() {


    var [nameAr, setNameAr] = useState()
    var [nameEn, setNameEn] = useState()
    var [image, setImage] = useState()
    const [loader, showLoader, hideLoader] = useLoader(); 

    const handleNewImage = event => {
        image = event.target.files[0]
      
      }
    const location = useLocation();
    const row = location.state.row;

   

    const navigateTo = useNavigate()
    const postForm = async () => {
         showLoader()
        //  if(handleValidation()==false)return;


        const formdata = new FormData()
        formdata.append("id", row.id)
         formdata.append("name",nameAr ?? row.name)
         formdata.append("nameEn",nameEn ?? row.nameEn)
         
        if (image!=null) {
            let uploadedImage = await uploadImage(image)
            formdata.append("image", uploadedImage)
        }else{
            formdata.append("image", row.image)
        }


        const response = await fetch(baseurl + "field/update", {
            method: "POST",
            headers: {

                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formdata
        });

        hideLoader()
        successNotify()

        const json = await response.json();
        console.log(json);
        navigateTo("/admin/fields");

    };




    return <div>
        {loader}
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">الفئات</div>
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                            <a href="javascript:;">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">تعدبل فئة</li>
                    </ol>
                </nav>
            </div>
        </div>
        {/*end breadcrumb*/}
        <hr />
        <div className="col-xl-12 mx-auto">
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <h6 className="mb-0 text-uppercase">معلومات الفئة</h6>
                        <hr />
                            <div asp-validation-summary="ModelOnly" className="text-danger" />
                            <div className="row g-3">
                                <div className="col-6">
                                    <label className="form-label">الإسم باللغة العربية </label>
                                    <input onChange={(e) => (nameAr=e.target.value )} defaultValue={row.name} autoComplete="off" asp-for="Name" type="text" className="form-control" />
                                    <span asp-validation-for="Name" className="text-danger" />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">الإسم باللغة الإنجليزية </label>
                                    <input onChange={(e) => (nameEn=e.target.value )} defaultValue={row.nameEn} autoComplete="off" asp-for="NameEn" type="text" className="form-control" />
                                    <span asp-validation-for="NameEn" className="text-danger" />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="ImageFile" className="form-label">صورة للفئة <span className="text-success">حجم الصورة (  260x260 )</span></label>
                                    <input onChange={handleNewImage} asp-for="ImageFile" className="form-control form-control-lg" id="formFileLg" type="file" />
                                    <span asp-validation-for="ImageFile" className="text-danger" />
                                </div>
                                <div onClick={postForm} className="col-12">
                                    <div className="d-grid">
                                        <input  type="submit" value="حفظ" className="btn btn-primary" />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}