

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseurl, OnInput, onInputInvalid, uploadImage } from "../../constants";



export default function CouponDetail() {

  const location = useLocation();
  const row = location.state.row;
 
  return <>




  <div>
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الكوبونات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">معلومات الكوبون</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات الكوبون</h6>
            <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
             
         

                <div className="col-6">
                  <label className="form-label"> نوع الكوبون </label>
                  { (
                    <select

                      onInvalid={onInputInvalid}
                      onInput={OnInput} required
                      name="status"
                      id="school-id"
                      className="form-select"
                    >

                      <option value={""}>
                        اختر
                      </option>
                      <option value={1}>
                        قيمة  : مثال 20 ريال
                      </option>
                      <option value={0}>
                          نسبة مثال 15%
                      </option>
                      
                    </select>
                  )}

                  <span asp-validation-for="ProductId" className="text-danger" />
                </div>



                <div className="col-6">
                  <label className="form-label">وصف مختصر عن الكوبون  </label>
                  <input  disabled onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="Name" type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">القيمة  </label>
                  <input  disabled onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="amount" type="number" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">الحد الاقصي للاستخدام  </label>
                  <input  disabled onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="quantity" type="number" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">تاريخ البداية  </label>
                  <input  disabled onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="startAt" type="date" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">تاريخ الانتهاء  </label>
                  <input  disabled onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="expiresAt" type="date" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>


               
             
                <div className="col-6">
                  <label className="form-label"> الحالة </label>
                  { (
                    <select

                      onInvalid={onInputInvalid}
                      onInput={OnInput} required
                      name="status"
                      id="school-id"
                      className="form-select"
                    >

                      <option value={""}>
                        اختر
                      </option>
                      <option value={1}>
                        مفعل
                      </option>
                      <option value={0}>
                        غير مفعل
                      </option>
                      
                    </select>
                  )}

                  <span asp-validation-for="ProductId" className="text-danger" />
                </div>
               
                <div className="col-12">
                  <div className="d-grid">
                    <input  disabled onInvalid={onInputInvalid}
                      onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>

<div className="m-3">
  <h6>الصور</h6>

  {
    row.image.split(",").map(e=><img style={{width: '20vw', height: 'auto'}} className="m-2" src={baseurl + "/uploads/" + e}  alt="Alt 1" />)
  }

</div>
</div>

  
  </>

}